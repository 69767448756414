header {
  border-bottom: 1px solid #ccc;
  margin-bottom: 1rem;
}

header>.container {
  padding: 0;
}

nav.navbar {
  display: flex;
  align-items: center;
  min-height: 60px;
  padding: 0.5rem 1rem;
}

nav.navbar>a>img.logo {
  height: 60px;
}

nav.navbar div.nav-links {
  display: flex;
  margin-left: auto;
}

nav.navbar div.nav-links img.logo {
  display: none;
  height: 60px;
}

nav.navbar .nav-link {
  display: block;
  color: #1c431e;
  text-decoration: none;
  margin-left: 3rem;
  font-size: 0.9rem;
}

nav.navbar .hamburger div {
  background: black;
  border-radius: 0.15rem;
  width: 1rem;
  height: 3px;
  margin-bottom: 2px;
}

nav.navbar div.nav-links button.close{
  display: none;
  font-weight: bold;
  font-size: 2rem;
  position: absolute;
  top: 1rem;
  right: 1rem;
}

nav.navbar button.collapsed-button {
  display: none;
  margin-left: auto;
}

.container {
  max-width: 1024px;
  margin: 0 auto;
  padding: 1rem;
}

.search-panel {
  display: flex;
}

.search-panel__filters {
  flex: 1;
}

.search-panel__results {
  flex: 3;
}

.ais-Highlight-highlighted {
  color: inherit;
  font-size: inherit;
}

#searchbox {
  margin-bottom: 2rem;
}

.filters-and-hits-container {
  display: flex;
}

.filters-and-hits-container .filters-container {
  flex: 0 0 auto;
}

.filters-container .filters-button-container {
  display: flex;
}

.filters-container .filter-button {
  display: none;
  flex: 0 0 auto;
  font-size: 1rem;
  margin-left: auto;
}

.filters-container .filter-button:after {
  content: url(../images/chevron-down.svg);
  width: 0.6rem;
  display:inline-block;
  margin-left: 0.25rem;
}

.filters-container.expanded .filter-button:after {
  content: url(../images/chevron-up.svg);
}

.filters-content {
  height: auto;
  overflow: hidden;
  margin: 1rem 0;
}

.filters-container.expanded .filters-content{
  height: auto;
  transition: height 0.5s;
}

.filters-container .filter-container {
  margin-bottom: 1rem;
}
  
.filters-container .filter-container .filter-header{
  text-transform: capitalize;
  font-weight: bold;
  padding: 0.25rem 0;
  border-bottom: 1px solid #ccc;
}


#pagination {
  margin: 2rem auto;
  text-align: center;
}

.products-list {
  justify-content: space-around;
}

.product {
  width: 300px;
  display: flex;
  align-items: start;
}

.product .product-image-container {
  height: 200px;
  overflow: hidden;
  display: flex;
  align-items: start;
}

.product img {
  flex: 0 0 auto;
  width: 100%;
}

.product .producttype {
  flex: 1 1 auto;
  text-align: center;
  font-weight: bold;
  font-size: 25px;
}


.product .brand {
  flex: 1 1 auto;
  text-align: center;
  font-weight: bold;
}

.product .content {
  flex: 1 1 auto;
}

.product .manufacturer {
  text-align: center;
}

.product .price::before {
  display: inline-block;
  margin-right: 0.3rem;
  content: "LBP ";
}
.product .price {
  font-size: 1rem;
  text-align: center;
}

.product a{
  text-decoration: none;
  color:  #3a4570;
}

.product-detail {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 3rem;
}

.product-detail img {
  flex: 1 1 auto;
  max-width: 400px;
}

.product-detail .product-info {
  padding: 1rem;
  margin: 1rem;
}

.product-detail .product-info-container {
  margin-bottom: 3rem;
}

.product-detail .product-info .product-detail-title {
  font-size: 0.85rem;
  font-weight: bold;
}

.product-detail .product-info .product-header {
  border-bottom: 2px solid #ccc;
}

.product-detail .product-info .product-title{
  font-weight: bold;
  font-size: 3rem;
}

.product-detail .product-info .product-brand{
  font-weight: bold;
  font-size: 1.5rem;
}

.product-detail .product-info .product-price{
  font-size: 1.5rem;
}

@media screen and (max-width: 500px) {
  nav.navbar div.nav-links button.close{
    display: block;
  }

  nav.navbar div.nav-links {
    padding-top: 4rem;
    text-align: center;
    height: 100vh;
    width: 100%;
    position: fixed; /* Stay in place */
    z-index: 4; /* Sit on top */
    left: 0;
    top: 0;
    background-color: rgb(255,255,255); /* Black fallback color */
    background-color: rgba(255,255,255); /* Black w/opacity */
    overflow-x: hidden; /* Disable horizontal scroll */
    transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
    display: none;
  }

  nav.navbar div.nav-links a.nav-link {
    font-size: 1.5rem;
    margin-top: 1.5rem;
  }

  nav.navbar div.nav-links.visible {
    display: block;
  }

  nav.navbar div.nav-links.visible button.close {
    display: block;
  }

  nav.navbar div.nav-links img.logo {
    display: none;
  }

  nav.navbar button.collapsed-button {
    display: block;
  }
}

.product-detail .product-image-container {
  flex: 1 1 auto;
  max-width: 400px;
}

.product-detail .product-image-container img {
  width: 100%;
}

.product-detail .product-info {
  padding: 1rem;
  margin: 1rem;
}

.product-detail .product-info-container {
  margin-bottom: 3rem;
}

.product-detail .product-info .product-detail-title {
  font-size: 0.85rem;
  font-weight: bold;
}

.product-detail .product-info .product-header {
  border-bottom: 2px solid #ccc;
}

.product-detail .product-info .product-title{
  font-weight: bold;
  font-size: 3rem;
}

.product-detail .product-info .product-brand{
  font-weight: bold;
  font-size: 1.5rem;
}

.product-detail .product-info .product-price{
  font-size: 1.5rem;
}


@media screen and (max-width: 900px) {
  .filters-and-hits-container {
    display: block;
  }

  .filters-container .filter-button {
    display: block;
  }

  .filters-content {
    height: 0;
  }
}

@media screen and (max-width: 500px) {
  nav.navbar div.nav-links button.close{
    display: block;
  }

  nav.navbar div.nav-links {
    padding-top: 4rem;
    text-align: center;
    height: 100vh;
    width: 100%;
    position: fixed; /* Stay in place */
    z-index: 4; /* Sit on top */
    left: 0;
    top: 0;
    background-color: rgb(255,255,255); /* Black fallback color */
    background-color: rgba(255,255,255); /* Black w/opacity */
    overflow-x: hidden; /* Disable horizontal scroll */
    transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
    display: none;
  }

  nav.navbar div.nav-links a.nav-link {
    font-size: 1.5rem;
    margin-top: 1.5rem;
  }

  nav.navbar div.nav-links.visible {
    display: block;
  }

  nav.navbar div.nav-links.visible button.close {
    display: block;
  }

  nav.navbar div.nav-links img.logo {
    display: none;
  }

  nav.navbar button.collapsed-button {
    display: block;
  }

  .product-detail {
    display: block;
  }

  .product-image-container {
    text-align: center;
  }

  .product-detail img.product-image{
    margin: auto;
    max-height: 450px;
  }
}

@media screen and (max-width: 900px) {
  .filters-and-hits-container {
    display: block;
  }

  .filters-container .filter-button {
    display: block;
  }

  .filters-content {
    height: 0;
  }
}
